var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"supplyBox"},[_c('pcHeader'),_c('div',{staticClass:"supplyimg"},[_c('img',{attrs:{"src":require("../assets/supply.jpg")}}),_c('div',{staticClass:"banner-title"},[_vm._v(_vm._s(_vm.$t("lang.supply.bannerTitle")))])]),_c('div',{staticClass:"supplcontainer"},[_c('div',{staticClass:"titles"},[_vm._v(_vm._s(_vm.$t("lang.supply.titles")))]),_c('div',{staticClass:"supplyDetail"},[_vm._v(_vm._s(_vm.$t("lang.supply.supplyDetail")))]),_c('div',{staticClass:"yini",style:({
        fontWeight: _vm.$i18n.locale === 'en-US' ? 'normal' : 'bold',
      })},[_vm._v(" "+_vm._s(_vm.$t("lang.supply.yini"))+" ")]),_c('div',{staticClass:"partbox"},[_c('div',{staticClass:"part"},[_c('div',{staticClass:"partname"},[_vm._v(_vm._s(_vm.$t("lang.supply.itemOne.partname")))]),_c('div',{staticClass:"partContent",style:({
            fontWeight: _vm.$i18n.locale === 'en-US' ? 'normal' : 'bold',
          })},[_vm._v(" "+_vm._s(_vm.$t("lang.supply.itemOne.partContent"))+" ")])]),_c('div',{staticClass:"part"},[_c('div',{staticClass:"partname"},[_vm._v(_vm._s(_vm.$t("lang.supply.itemTwo.partname")))]),_c('div',{staticClass:"partContent",style:({
            fontWeight: _vm.$i18n.locale === 'en-US' ? 'normal' : 'bold',
          })},[_vm._v(" "+_vm._s(_vm.$t("lang.supply.itemTwo.partContent"))+" ")])]),_c('div',{staticClass:"part"},[_c('div',{staticClass:"partname"},[_vm._v(_vm._s(_vm.$t("lang.supply.itemThree.partname")))]),_c('div',{staticClass:"partContent",style:({
            fontWeight: _vm.$i18n.locale === 'en-US' ? 'normal' : 'bold',
          })},[_vm._v(" "+_vm._s(_vm.$t("lang.supply.itemThree.partContent"))+" ")])])])]),_c('pcFooter')],1)}
var staticRenderFns = []

export { render, staticRenderFns }