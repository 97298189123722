<template>
  <div class="supplyBox">
    <pcHeader />
    <div class="supplyimg">
      <img src="../assets/supply.jpg" />
      <div class="banner-title">{{ $t("lang.supply.bannerTitle") }}</div>
    </div>
    <div class="supplcontainer">
      <div class="titles">{{ $t("lang.supply.titles") }}</div>
      <div class="supplyDetail">{{ $t("lang.supply.supplyDetail") }}</div>
      <div
        :style="{
          fontWeight: $i18n.locale === 'en-US' ? 'normal' : 'bold',
        }"
        class="yini"
      >
        {{ $t("lang.supply.yini") }}
      </div>
      <div class="partbox">
        <div class="part">
          <div class="partname">{{ $t("lang.supply.itemOne.partname") }}</div>
          <div
            class="partContent"
            :style="{
              fontWeight: $i18n.locale === 'en-US' ? 'normal' : 'bold',
            }"
          >
            {{ $t("lang.supply.itemOne.partContent") }}
          </div>
        </div>
        <div class="part">
          <div class="partname">{{ $t("lang.supply.itemTwo.partname") }}</div>
          <div
            class="partContent"
            :style="{
              fontWeight: $i18n.locale === 'en-US' ? 'normal' : 'bold',
            }"
          >
            {{ $t("lang.supply.itemTwo.partContent") }}
          </div>
        </div>
        <div class="part">
          <div class="partname">{{ $t("lang.supply.itemThree.partname") }}</div>
          <div
            class="partContent"
            :style="{
              fontWeight: $i18n.locale === 'en-US' ? 'normal' : 'bold',
            }"
          >
            {{ $t("lang.supply.itemThree.partContent") }}
          </div>
        </div>
      </div>
    </div>
    <pcFooter />
  </div>
</template>
<script>
import pcHeader from "@/components/pcHeader";
import pcFooter from "@/components/pcFooter";
export default {
  components: {
    pcHeader,
    pcFooter,
  },
};
</script>
<style lang="scss">
.supplyBox {
  .supplyimg {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .banner-title {
      position: absolute;
      font-size: 55px;
      color: #fff;
      font-weight: bold;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .supplcontainer {
    width: 1200px;
    margin: 0 auto;
    margin-top: 120px;
    .titles {
      font-size: 26px;
      font-weight: bold;
    }
    .supplyDetail {
      font-weight: bold;
      font-size: 20px;
      color: #6d7276;
      margin-top: 40px;
    }
    .yini {
      font-size: 20px;
      font-weight: bold;
      margin-top: 60px;
    }
  }
  .partbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 100px;
    margin-bottom: 100px;
    .part {
      font-weight: bold;
      //width: calc(100% / 3);
      .partname {
        font-size: 26px;
      }
      .partContent {
        font-size: 20px;
      }
    }
  }
}
</style>
